import { useEffect, useState } from "react";
import iconCopy from "../../../assets/images/teleImage/icon_copy.png";
import TonImg from "../../../assets/images/common/TonImg.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import ChoosePlanFarming from "../choosePlanFarming";
import styles from "./index.module.scss";
import { writeClipboardText } from "../../../utils/function/text";
import { collapseAddress } from "../../../core/utils";
interface IModalCoppyAddressType {
	addressWallet?: string;
	isShowPaymentTon?: boolean;
	onClose?: () => void;
	onTonPayment?: () => void;
	isTonPayment?: boolean
}
const ModalCoppyAddress = ({
	addressWallet,
	onClose,
	isShowPaymentTon,
	onTonPayment,
	isTonPayment
}: IModalCoppyAddressType) => {
	const [showCheckIn, setShowCheckIn] = useState<boolean>(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);
	return (
		<ChoosePlanFarming showIcon={false} onClose={onClose}>
			<div className={ isTonPayment ?  styles.deposit : styles.deposit1}>
				<h3 className={styles.deposit__title}>
					Insufficient balance.{" "}
				</h3>
				<p className={styles.deposit__content}>
					Please deposit APT into your wallet to complete the
					purchase.
				</p>
				{addressWallet && (
					<div className={styles.deposit__address}>
						<p>{collapseAddress(addressWallet)}</p>
						<img
							src={iconCopy}
							alt="iconCopy"
							width={25}
							onClick={() => {
								writeClipboardText(addressWallet);
								setShowCheckIn(true);
							}}
						/>
					</div>
				)}
				{showCheckIn && <div className={styles.copyStyle1}>Copied</div>}
				<CircleButton
					isGrayColor={false}
					title="Click to copy address"
					onTap={() => {
						writeClipboardText(addressWallet);
						setShowCheckIn(true);
					}}
				/>
				{isTonPayment && <h3 className={styles.deposit__content}>OR payment with TON</h3>}
				{isTonPayment && <p
					className={`${styles.button} ${styles.ton} active-btn`}
					onClick={async () => {
						onTonPayment!();
						onClose!();
					}}
				>
					<img src={TonImg} alt="TON" /> TON{" "}
				</p>}
			</div>
		</ChoosePlanFarming>
	);
};

export default ModalCoppyAddress;

/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../../../../assets/roll/button_cancel.png";
import bg_rectangle from "../../../../../../assets/images/switch_account.svg";
import cardRate from "../../../../../../assets/images/marketPlace/selectImg.png";
import CircleButton from "../../../../../../commonsUi/commonButton/gameButton/CircleButton/CircleButton";
import { useEffect, useState } from "react";
import { Account, Ed25519PrivateKey } from "@aptos-labs/ts-sdk";
import { JWT_SIGNING } from "../../../../../../utils/constants";
import { myWalletAddress } from "../../../../../../utils/aptos/aptosStorage";
import CryptoJS from "crypto-js";
import { encodeJwt } from "../../../../../../utils/extension";
import { apiRegisterAccountWithAddress } from "../../../../../../services/claimApiService";
import LoadingCommon from "../../../../../../commonsUi/CommonUI/LoadingApp";
import { useLocation } from "react-router-dom";

type WitchAccountType = {
	onClose: () => void;
	onSignGoogle: () => void,
	onSwitch: ({ value }: any) => void;
};

export default function SwitchAccountModal({
	onClose,
	onSwitch,
	onSignGoogle
}: WitchAccountType) {
	const [inputValue, setInputValue] = useState("");
	const [isShowLoading, setShowLoading] = useState(false);
	const [telegramId, setTelegramId] = useState<string | null>(null);
	const handleInputChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setInputValue(event.target.value);
	};

	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const userId: any = params.get("user_id");
		if (userId) {
			setTelegramId(userId);
		}
	}, []);
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					src={bg_rectangle}
					width={380}
					alt="slibg_rectangleme"
					className={style.backgroundiMG}
				/>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<div className={style.childStyle}>
					<p className={style.titleSwitchAccount}>Switch Account</p>
						{!telegramId && <div
							className={style.buttonLoginGoogle}
							id="button_login_aptos"
							onClick={() => {
								onSignGoogle()
								onClose();
							}}
						>
							<p>Sign in with google</p>
						</div>}
						{!telegramId  && <p className={style.titleSwitchAccount}>OR</p>}
					<p className={style.desSwichAcc}>
						Paste your private key string
					</p>
					<textarea
						value={inputValue}
						className={style.inputStyle}
						onChange={handleInputChange}
						placeholder="e.g. 
0x000019954520x000019954520x000019954520x000019954520x000019954520x000019954520x00001995452"
						rows={5}
					/>
					<CircleButton
						title="Import"
						onTap={async () => {
							setShowLoading(true);
							const privateKey = new Ed25519PrivateKey(
								inputValue
							);
							const account = Account.fromPrivateKey({
								privateKey,
							});
							if (account.accountAddress) {
								console.log(account.accountAddress);
								const encryptedPrivateKey =
									CryptoJS.AES.encrypt(
										inputValue,
										JWT_SIGNING
									).toString();
								localStorage.setItem(
									"encryptedPrivateKey",
									encryptedPrivateKey
								);
								console.log(myWalletAddress());
								const payloadApi = {
									WalletAddress: myWalletAddress(),
									WalletType: "NormalAptos",
								};

								const payLoadData1 = await encodeJwt(
									payloadApi
								);
								const responseRegisterAccount: any =
									await apiRegisterAccountWithAddress({
										value: payLoadData1,
									});
								console.log(
									responseRegisterAccount?.data?.access_token
								);
								localStorage.setItem(
									"_gameAccessToken",
									responseRegisterAccount?.data?.access_token
								);
								localStorage.setItem(
									"info_data",
									JSON.stringify(
										responseRegisterAccount?.data
									)
								);
								console.log({ responseRegisterAccount });
								setShowLoading(false);
								onClose();
							}
						}}
						isGrayColor={inputValue === ""}
					/>
				</div>
				<LoadingCommon
					visible={isShowLoading}
					width={80}
					height={80}
					isLeft10={true}
				/>
			</div>
		</div>
	);
}

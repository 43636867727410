import React, { useEffect, useState } from "react";
import styles from "./welcome_chest.module.scss";
import textTap from "../../../../../../assets/images/teleImage/arrow_bottom_img.png";
import iconOpenChest from "../../../../../../assets/images/teleImage/openCheest.png";
import iconChest from "../../../../../../assets/images/teleImage/Chest.png";
import logo from "../../../../../../assets/images/logoWebGame.png";
import { apiRewardTspc } from "../../../../../../services/accountApiService";
import iconTab from "../../../../../../assets/images/teleImage/hand_tap.png";
import imageRewardGold from "../../../../../../assets/images/teleImage/reward_gold.png";
import { i } from "@aptos-labs/ts-sdk/dist/common/accountAddress-Lv-GSihU";
import LoginGame from "../../Login";
import ReactGA from "react-ga4";
import {
	sendRequestGoogleAnalyticsNew,
	trackEvent,
} from "../../../../../../services/ga4";

enum Visibility {
	Visible = "visible",
	Hidden = "hidden",
}

declare global {
	interface Window {
		gtag: (...args: any[]) => void;
	}
}

declare global {
	interface Window {
		dataLayer: Record<string, any>[];
	}
}
interface IWelcomeChest {
	fncShowPreLoading: React.Dispatch<React.SetStateAction<boolean>>;
	fncLogin: () => void;
}

const WelcomeChest = ({ fncShowPreLoading, fncLogin }: IWelcomeChest) => {
	const [countTab, setCountTab] = useState(0);
	const [indexTab, setIndexTab] = useState<number>(1);
	const [statusLoading, setStatusLoading] = useState<Visibility | undefined>(
		Visibility.Visible
	);
	const [showWalletConnect, setShowWalletConnect] = useState<boolean>(false);

	const handleTabChest = () => {
		trackEvent("tab_chest", "tab_chest", "tab_chest", 1);

		setCountTab((prev) => (indexTab === 2 ? prev + 1 : prev));
	};

	const handleApiRewardTspc = async () => {
		// await apiRewardTspc();
		console.log("check", ReactGA.gtag());
		localStorage.setItem("__showPreLoad", "1");
	};

	useEffect(() => {
		// window.addEventListener("gtm.load", () => {
		ReactGA.initialize("G-FLSSKTELR4");
		// });
	}, []);

	useEffect(() => {
		if (countTab >= 1 && indexTab === 2) {
			handleApiRewardTspc();
			setTimeout(() => {
				// setStatusLoading(Visibility.Hidden);
				// setShowWalletConnect(true);
				// fncShowPreLoading(false);
				trackEvent("pass_preload", "pass_preload", "pass_preload", 1);
			}, 2000);
		}
	}, [countTab]);

	useEffect(() => {
		console.log("hello");
		ReactGA.event({
			category: "preload",
			action: "start_reload",
			label: "Start Preload",
		});

		// window.gtag("event", "button_click", {
		// 	event_category: "engagement",
		// 	event_label: "homepage_button",
		// 	value: 1,
		// });

		window.dataLayer.push({ event: "preload" });
		console.log("After push:", window.dataLayer);
		setTimeout(() => setIndexTab(2), 3000);
	}, []);

	return (
		<div
			className={styles.wrapper}
			style={{
				visibility: statusLoading,
			}}
		>
			{/* <div
				style={{
					display: showWalletConnect ? "block" : "none",																																																								
				}}
			>
				<LoginGame fncLogin={fncLogin} />
			</div> */}
			{countTab < 1 && (
				<>
					<div className={styles.logo}>
						<img src={logo} alt="logo" />
					</div>

					<div
						className={styles.text__welcome}
						style={{
							height: `${window.innerHeight < 780 ? 275 : 360}px`,
							marginTop: `${
								window.innerHeight < 780 ? 50 : 70
							}px`,
						}}
					>
						<p className={styles.text__welcome__top}>
							Welcome to Slime Revolution
						</p>
						{indexTab === 2 ? (
							<TextChestTab />
						) : (
							<p
								className={styles.text__welcome__below}
								id="text_first_social"
							>
								The first social NFT Game build on APTOS
							</p>
						)}
					</div>
				</>
			)}

			<div className={styles.group__chest}>
				{countTab >= 1 && indexTab === 2 && !showWalletConnect ? (
					<>
						<p
							className={styles.text__welcome__top}
							style={{
								marginTop: "100px",
								marginBottom: "10px",
							}}
						>
							Congrats! You got 100 tSPC!
						</p>
						<img
							className={styles.reward_gold}
							src={imageRewardGold}
							width={"300px"}
							height={"auto"}
							alt="icon chest"
							onClick={handleTabChest}
						/>
						<img
							className={styles.chest_open}
							src={iconOpenChest}
							width={`${window.innerHeight < 780 ? 270 : 300}px`}
							height={"auto"}
							alt="icon chest"
							onClick={handleTabChest}
							style={{
								bottom: `${
									window.innerHeight < 780 ? 15 : 70
								}px`,
							}}
						/>
					</>
				) : (
					<>
						<img
							className={`${styles.chest} ${styles.animation_start}`}
							src={iconChest}
							width={`${window.innerHeight < 780 ? 246 : 276}px`}
							height={"auto"}
							alt="icon chest"
							onClick={handleTabChest}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default WelcomeChest;

const TextChestTab = () => {
	return (
		<>
			<div
				className={`${styles.text__welcome__top} ${styles.text_to_tab}`}
				style={{
					fontSize: `${
						window.innerHeight < 780 ? 25 : 26
					}px !important`,
				}}
			>
				<p id="text_tab_tab_claim">Tap Tap Tap to claim</p>
				<p>A Special Gift for you!</p>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<img
					className={styles.textTap}
					src={iconTab}
					alt="icon tab"
					width={87}
				/>
			</div>
		</>
	);
};

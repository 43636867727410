/** @format */

import React, { useEffect } from "react";

export default function HamsterComponents() {
	useEffect(() => {
	}, []);
	return (
		<div
		>
			<p>{}</p>
			{localStorage.getItem("_gameAccessToken") != null && (
				<iframe
					title="Island Game"
					id="isLandIframe"
					style={{
						width: "100%",
						height: "100vh",
					}}
					src={`${
						process.env.REACT_APP_HAMSTER_LINK
					}?access_token=${localStorage.getItem(
						"_gameAccessToken"
					)}&user_id=${localStorage.getItem("_userId")}?time=1`}
				></iframe>
			)}
		</div>
	);
}

/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import Mysterycard from "../../../assets/images/card/cardLevelInfo.png";
import bg_rectangle from "../../../assets/roll/bg_rectangle.svg";
import cardRate from "../../../assets/images/card/cardRate.png";
import backImg from "../../../assets/images/ranking/bacnk.png";
import nextImg from "../../../assets/images/ranking/next.png";
import startImg from "../../../assets/roll/card/start.png";
import { RATE_NFT_ISLANDLVL } from "../../../utils/constants";
import { useEffect, useState } from "react";
import StarRating from "../ModalBurnCard/StartRating";
type CardLevelInfoType = {
	cardLevel: number;
	onClose: () => void;
};

export default function CardLevelInfoModal({
	onClose,
	cardLevel,
}: CardLevelInfoType) {
	const [indexSelection, setIndexSelection] = useState(0);
	const levels = [1, 2, 3, 4, 5];
	useEffect(() => {
		setIndexSelection(cardLevel - 1);
	}, []);
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img
					src={bg_rectangle}
					width={380}
					alt="slibg_rectangleme"
					className={style.backgroundiMG}
				/>

				<img
					src={Mysterycard}
					width={261}
					alt="slime"
					className={style.iconStyle}
				/>
				<img
					src={cardRate}
					width={251}
					alt="slime"
					className={style.cardRateStyle}
				/>
				<div className={style.childStyle}>
					<p className={style.textCardStyle}>
						The higher the Island Level, the higher the chance of
						getting rare cards.
					</p>
					<p className={style.card_level}>
						Your Card Level: {cardLevel}
					</p>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<img
							src={backImg}
							alt="backImg"
							onClick={() => {
								if (indexSelection === 0) {
									setIndexSelection(15);
								} else {
									setIndexSelection(indexSelection - 1);
								}
							}}
							width={40}
							className={style.leftStyle}
							height={40}
						/>
						<div className={style.containerStyle}>
							{levels.map((e, i) => (
								<div className={style.itemStyle}>
									<p className={style.centenStyle}>
										{RATE_NFT_ISLANDLVL[indexSelection][
											`RARITY${i + 1}`
										] / 1000}
										%
									</p>
									<StarRating count={e} />
								</div>
							))}
						</div>
						<img
							src={nextImg}
							onClick={() => {
								if (indexSelection === 15) {
									setIndexSelection(0);
								} else {
									setIndexSelection(indexSelection + 1);
								}
								console.log(indexSelection);
							}}
							className={style.rightStyle}
							alt="nextImg"
							width={40}
							height={40}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

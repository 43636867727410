import {
	useWallet,
	WalletName,
	InputTransactionData,
} from "@aptos-labs/wallet-adapter-react";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import { AptosClient, TxnBuilderTypes } from "aptos";
import { devnetClient } from "./core/constants";
import { getLocalKeylessAccount, getWalletFromLocalStorage } from "./utils/aptos/aptosStorage";

export const NODE_URL = "https://fullnode.testnet.aptoslabs.com/v1";
export const clientAptos = new AptosClient(NODE_URL);

export const MODULE_ADDRESS =
	"0x08afb046f44dd0cb9c445458f9c2e424759cd11f4a270fe6739dcffc16a4db8e";

export const ADDRESS_GET_MONEY =
	"0x08afb046f44dd0cb9c445458f9c2e424759cd11f4a270fe6739dcffc16a4db8e";

export const MARKET_PLACE_CONTRACT = "slime_nft_market_place";
export const aptosConfig = new AptosConfig({ network: Network.MAINNET });
export const aptos = new Aptos(aptosConfig);
export const APTOS_COIN = "0x1::aptos_coin::AptosCoin";
export const COIN_STORE = `0x1::coin::CoinStore<${APTOS_COIN}>`;

export const convertAptToOctas = (aptAmount: number): number => {
	const OCTAS_PER_APT = 100_000_000;
	return aptAmount * OCTAS_PER_APT;
};

export const convertOctasToApt = (octasAmount: number): number => {
	const OCTAS_PER_APT = 100_000_000;
	return octasAmount / OCTAS_PER_APT;
};
export const getUserOwnedSlimeNFT = async () => {
	const result: any =
		await devnetClient.getAccountOwnedTokensFromCollectionAddress({
			accountAddress: getWalletFromLocalStorage()!.accountAddress,
			collectionAddress:
				"0xd08279357256b766ae5e59a847a58206c2d3d56b0cf2483b2562508e174c087b",
		});

	if (!Array.isArray(result)) {
		console.error("Expected an array as the result.");
		return null;
	}

	const maxTimestampItem = result.reduce((maxItem, currentItem) => {
		return new Date(currentItem.last_transaction_timestamp) > new Date(maxItem.last_transaction_timestamp)
			? currentItem
			: maxItem;
	}, result[0]);

	console.log("Max Timestamp Item:", maxTimestampItem);

	return maxTimestampItem;
};
export const getAptosClient = getLocalKeylessAccount();

import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { FaBook } from "react-icons/fa";
import { devnetClient, GOOGLE_CLIENT_ID } from "../../../../core/constants";
import useEphemeralKeyPair from "../../../../core/useEphemeralKeyPair";
import { requestUpdateProfile } from "../../../../services/accountApiService";
import { collapseAddress } from "../../../../core/utils";
import { useNavigate } from "react-router-dom";
import { useKeylessAccounts } from "../../../../core/useKeylessAccounts";
import {
	aptos,
	MARKET_PLACE_CONTRACT,
	MODULE_ADDRESS,
} from "../../../../aptosConfig";
import {
	getLocalKeylessAccount,
	logOutKeylessAccount,
} from "../../../../utils/aptos/aptosStorage";
import logoAptos from "../../../../assets/images/teleImage/logo_aptos.png";

export default function ConenctWalletPage() {
	const ephemeralKeyPair = useEphemeralKeyPair();

	const redirectUrl = new URL("https://accounts.google.com/o/oauth2/v2/auth");

	const searchParams = new URLSearchParams({
		client_id: GOOGLE_CLIENT_ID,
		redirect_uri: `${window.location.origin}/callback`,
		response_type: "id_token",
		scope: "openid email profile",
		nonce: ephemeralKeyPair.nonce,
	});
	const navigate = useNavigate();

	const { activeAccount } = useKeylessAccounts();

	console.log(activeAccount?.accountAddress.toString());

	const updateAccountWallet = async () => {
		await requestUpdateProfile(
			activeAccount?.accountAddress.toString(),
			"Aptos"
		);
	};

	useEffect(() => {
		if (activeAccount) {
			updateAccountWallet();
		}
	}, [activeAccount, navigate]);

	redirectUrl.search = searchParams.toString();

	useEffect(() => {
		localStorage.setItem("nonceKeyless", ephemeralKeyPair.nonce);
	}, []);

	const YourComponent = () => {
		return (
			<div className={styles.container}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						columnGap: "10px",
						fontFamily: "Poppins",
					}}
				></div>
				<p className={styles.subtitle}>
					{`Connect Aptos wallet to Launch the game`}
				</p>

				<a
					href={redirectUrl.toString()}
					className={styles.buttonLoginGoogle}
					id="button_login_aptos"
				>
					<p>Continue with Google</p>
				</a>
				{activeAccount && (
					<p>
						{collapseAddress(
							activeAccount?.accountAddress.toString()
						)}
					</p>
				)}
				<div className={styles.groupLoginBottom}>
					<p>Power by</p>
					<img src={logoAptos} alt="icon aptos" width={"65px"} />
				</div>
			</div>
		);
	};

	return (
		<div className={`${styles.loading__game1}`}>
			<div className={styles.loadingContainer}>
				<YourComponent />
			</div>
		</div>
	);
}

/** @format */

// Button.js
import React from "react";
import styles from "./commonButtonStyle.module.scss"; // Import CSS module for styling
import { ColorButton } from "./ColorButton";

const CommonPriceButton = ({ onClick, children,}) => {
    return (
        <button
            className={`${styles.priceButtonStyle} active-btn`}
            onClick={onClick}>
            {children}
        </button>
    );
};

export default CommonPriceButton;

import { getWalletFromLocalStorage } from "../utils/aptos/aptosStorage";

export const collapseAddress = (address: string): string => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};
export const convertUint8ArrayToHexString = (data: Uint8Array): string => {
  return Array.from(data)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
};

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import TeleAppPage from "./components/page/TeleAppPage";
import PaymentPage from "./components/page/Payment";
import CallbackPage from "./CallbackPage";
import HomePage from "./HomePage";
import ConenctWalletPage from "./components/page/TeleAppPage/ConnectWalletPage";
import HamsterPage from "./components/page/TeleAppPage/hamsterPage";


const Main = () => {
	

	return (
		<Router>
			<Routes>
				<Route path="/" element={<TeleAppPage />} />
				<Route path="/kickHamster" element={<HamsterPage />} />
				<Route path="/payment" element={<PaymentPage />} />
				<Route path="/callback" element={<CallbackPage />} />
				<Route path="/home" element={<HomePage />} />
				<Route path="/siginGoolge" element={<ConenctWalletPage />} />
			</Routes>
		</Router>
	);
};

export default Main;

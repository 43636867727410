/** @format */

import { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import arowBottomImg from "../../assets/images/miniDropdown.png";
import { collapseAddress } from "../../core/utils";

type SelectChainType = {
	onSelect: ({ value }: any) => void;
	options: String[];
	cardSelected: string;
};

const OptionSelection = ({ onSelect, options,cardSelected }: SelectChainType) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState<String | null>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const toggleDropdown = () => setIsOpen(!isOpen);

	const handleOptionClick = (option: String) => {
		onSelect(option);
		setSelectedOption(option);
		setIsOpen(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		setSelectedOption(cardSelected)
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div
			style={{
				display: "flex",
				justifySelf: "center",
				alignSelf: "center",
				width: "380px",
				alignItems: "center",
				alignContent: "center",
				justifyContent: "start",
			}}
		>
			<div className={styles.dropdown} ref={dropdownRef}>
				<button
					onClick={toggleDropdown}
					className={styles.dropdownToggle}
				>
					{selectedOption
						? collapseAddress(selectedOption!.toString())
						: "Choose ID"}
					<p></p>
					<img src={arowBottomImg} alt="arowBottomImg" width={16} />
				</button>
				<ul
					className={`${styles.dropdownMenu} ${
						isOpen ? styles.open : ""
					}`}
				>
					{options.length > 0 &&
						options.map((option) => (
							<div
								style={{
									marginTop: "8px",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										alignContent: "center",
										justifyContent: "start",
										paddingLeft: "10px",
									}}
								>
									<li
										key={option.toString()}
										onClick={() =>
											handleOptionClick(option)
										}
										className={styles.dropdownItem}
									>
										{collapseAddress(option!.toString())}
									</li>
								</div>
								<div className={styles.dividerStyle}></div>
							</div>
						))}
				</ul>
			</div>
		</div>
	);
};

export default OptionSelection;

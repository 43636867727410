import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import swapIcon from "../../../assets/images/swapIcon.png";
import LoadingCommon from "../../CommonUI/LoadingApp";
import OptionSelection from "../../CommonSellection";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import {
    getAllCardOfID,
    sellCardNft,
} from "../../../services/accountApiService";
import {
    feePayer,
    getLocalKeylessAccount,
    getWalletFromLocalStorage,
} from "../../../utils/aptos/aptosStorage";
import { devnetClient } from "../../../core/constants";
import {
    convertAptToOctas,
    MARKET_PLACE_CONTRACT,
    MODULE_ADDRESS,
} from "../../../aptosConfig";
import { APTOS_COIN } from "@aptos-labs/ts-sdk";
import { encodeJwt } from "../../../utils/extension";
import ChoosePlanFarming from "../choosePlanFarming/index";
import Slime from "../../../assets/roll/Slime.svg";
import AptosWalletConnect from "../../../components/page/TeleAppPage/walletConnect";

type SellCardModalType = {
    cardId?: number;
    children?: React.ReactNode;
    iconPopup?: any;
    onClose?: () => void;
    onUpdate: () => void;
    isLoading?: boolean;
    customStyle?: React.CSSProperties;
    showIcon?: boolean;
    showClose?: boolean;
};
export default function SellCardModal({
    cardId,
    children,
    iconPopup,
    onClose,
    onUpdate,
    isLoading = false,
    customStyle,
    showIcon = true,
    showClose = true,
}: SellCardModalType) {
    const [inputValue, setInputValue] = useState<number | "">(0);
    const [cardIds, setCardIds] = useState<string[]>([]);
    const [aptUsd, setAptUsd] = useState<number | string>(0);
    const [aptUsd1, setAptUsd1] = useState<number | string>(0);
    const [nftAddressSelected, setNftAddressSelected] = useState("");
    const [isListSuccess, setIsListSuccess] = useState(false);
    const [isShowLoading, setShowLoading] = useState(false);
    const [isShowConnectWallet, setConnectWallet] = useState(false);
    const [isShowAlert, setShowAlert] = useState("");
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        console.log({ value });

        if (value === "" || /^(\d*\.?\d*)$/.test(value)) {
            setInputValue(value === "" ? "" : parseFloat(value));
            convertAptUsd(value === "" ? 0 : parseFloat(value));
        }
    };

    const getAllCardOfId = async () => {
        const res: any = await getAllCardOfID(cardId!);
        console.log(res);
        setCardIds(res.data);
        setNftAddressSelected(res.data[0]);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAlert("");
        }, 2000);
        return () => clearTimeout(timer);
    }, [isShowAlert]);
    const convertAptUsd = async (amount: number) => {
        const bnbUsdtPriceResponse = await fetch(
            "https://api.binance.com/api/v3/ticker/price?symbol=APTUSDT"
        );
        const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
        console.log(bnbUsdtPriceData.price);
        setAptUsd1(bnbUsdtPriceData.price);
        setAptUsd(amount * bnbUsdtPriceData.price);
    };
    const getAllListingObjectAddresses = async () => {
        const allListings: [string[]] = await devnetClient.view({
          payload: {
            function: `${MODULE_ADDRESS}::${MARKET_PLACE_CONTRACT}::get_seller_listings`,
            typeArguments: [],
            functionArguments: [getWalletFromLocalStorage()!.accountAddress],
          },
        });
        console.log("all listings", allListings);
        
        return allListings[0].slice(-1)[0];
      };

      
    const listNFT = async () => {
        try {
            setShowLoading(true);
            const transaction = await devnetClient.transaction.build.simple({
                withFeePayer: true,
                sender: getWalletFromLocalStorage()!.accountAddress,
                data: {
                    function: `${MODULE_ADDRESS}::${MARKET_PLACE_CONTRACT}::list_with_fixed_price`,
                    typeArguments: [APTOS_COIN],
                    functionArguments: [
                        nftAddressSelected,
                        convertAptToOctas(inputValue !== "" ? inputValue : 0),
                    ],
                },
            });
            const feePayerAuthenticator =
                devnetClient.transaction.signAsFeePayer({
                    signer: feePayer,
                    transaction,
                });
            const senderAuthenticator = devnetClient.transaction.sign({
                signer: getWalletFromLocalStorage()!,
                transaction,
            });
            const committedTransaction =
                await devnetClient.transaction.submit.simple({
                    transaction,
                    senderAuthenticator: senderAuthenticator!,
                    feePayerAuthenticator: feePayerAuthenticator,
                });
            const response = await devnetClient.waitForTransaction({
                transactionHash: committedTransaction.hash,
            });

            console.log(`Committed transaction: ${response}`);
            const lastListing = await getAllListingObjectAddresses();
            console.log({ lastListing });
            const value = {
                card_id: cardId,
                token_id: nftAddressSelected,
                price: inputValue,
                market_token_id: lastListing,
            };
            const payLoadData = await encodeJwt(value);
            await sellCardNft({ value: payLoadData });
            setIsListSuccess(true);
            setShowLoading(false);
            onUpdate();
        } catch (e: any) {
            if (e?.message === "EphemeralKeyPair is expired") {
                setConnectWallet(true);
            }
            setShowLoading(false);
            console.log({ e });
        }
    };

    useEffect(() => {
        getAllCardOfId();
        convertAptUsd(0);
    }, []);

    return (
        <div className={style.main}>
            <div className={`${style.popup}`}>
                <LoadingCommon
                    visible={isLoading}
                    width={80}
                    height={80}
                    isLeft10={true}
                />
                {showIcon && (
                    <img
                        src={iconPopup}
                        width={121}
                        alt="slime"
                        className={style.popup__icon}
                        style={customStyle}
                    />
                )}
                {showClose && (
                    <img
                        src={buttonCancel}
                        width={36}
                        height={38}
                        alt="button cancel"
                        className={style.button__close}
                        onClick={onClose}
                    />
                )}
                <div className={style.childStyle}>
                    <p className={style.titleSelection}>
                        Choose the ID of selling card
                    </p>
                    {nftAddressSelected !== "" && (
                        <OptionSelection
                            cardSelected={nftAddressSelected}
                            options={cardIds}
                            onSelect={(value) => {
                                setNftAddressSelected(value);
                                console.log({ value });
                            }}
                        />
                    )}

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "320px",
                            marginBottom: "4px",
                            marginTop: "10px",
                        }}
                    >
                        <div>
                            <p className={style.titleSelection}>
                                Set the Price
                            </p>
                            <input
                                type="number"
                                className={style.inputStyle}
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                columnGap: "10px",
                                marginRight: "30px",
                            }}
                        >
                            <p className={style.contentStyle}>APT</p>
                            <img src={swapIcon} alt="swapIcon" width={20} />
                            <p className={style.contentStyle}>
                                {parseFloat(String(aptUsd)).toFixed(2)}
                            </p>
                        </div>
                        <p className={style.contentStyle}>USD</p>
                    </div>
                    <p className={style.contentStyle}>
                        {`* Current exchange rate: 1 APT = ${parseFloat(
                            String(aptUsd1)
                        ).toFixed(2)} USD`}
                    </p>

                    <div
                        style={{
                            width: "88%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircleButton
                            isGrayColor={
                                nftAddressSelected === "" ||
                                inputValue === 0 ||
                                inputValue.toString() === ""
                            }
                            title="Sell now"
                            onTap={() => {
                                if (
                                    !(
                                        nftAddressSelected === "" ||
                                        inputValue === 0 ||
                                        inputValue.toString() === ""
                                    )
                                ) {
                                    listNFT();
                                } else {
                                    if (
                                        inputValue === 0 ||
                                        inputValue.toString() === ""
                                    ) {
                                        setShowAlert("Please enter price");
                                    } else {
                                        setShowAlert("Please Select card id");
                                    }
                                }

                            }}
                        />
                    </div>
                    <p
                        className={style.contentStyle}
                        style={{
                            marginLeft: "20%",
                            marginTop: "6px",
                        }}
                    >
                        *A small gas fee will be applied
                    </p>
                </div>
                {isListSuccess && (
                    <ChoosePlanFarming
                        iconPopup={Slime}
                        onClose={() => setIsListSuccess(false)}
                    >
                        <div
                            style={{
                                marginTop: "35%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                rowGap: "16px",
                            }}
                        >
                            <p className={style.listSuccessfulStyle}>
                                List Successful
                            </p>
                            <CircleButton
                                title="Sell more"
                                onTap={() => {
                                    setIsListSuccess(false);
                                    onClose!();
                                }}
                            />
                            {/* <CircleButton
                                isGrayColor={true}
                                title="check my collection"
                                onTap={() => setIsListSuccess(false)}
                            /> */}
                        </div>
                    </ChoosePlanFarming>
                )}
                {isShowConnectWallet && (
                    <AptosWalletConnect
                        isPreload={false}
                        onClose={() => {
                            setConnectWallet(false);
                        }}
                    />
                )}
                {isShowAlert !== "" && (
                    <div className={style.notice3}>{isShowAlert}</div>
                )}
                <LoadingCommon
                    visible={isShowLoading}
                    width={80}
                    height={80}
                    isLeft10={true}
                />
            </div>
            <div className={`${style.modalOverlay}`}></div>
        </div>
    );
}


import ReactGA from "react-ga4";
export const initGA = (measurementId: string) => {
	ReactGA.initialize(measurementId);
};

export const logPageView = () => {
	ReactGA.send({
		hitType: "pageview",
		page: window.location.pathname + window.location.search,
	});
};

export const trackEvent = (
	category: string,
	action: string,
	label?: string,
	value?: number
) => {
	console.log("start tracking ...");

	try {
		ReactGA.event({
			category,
			action,
			label,
			value,
		});
	} catch (e) {
		console.log(e);
	}
};

interface EventParams {
	key: string;
	value: string;
}

interface DataSendGa4 {
	en: string;
	ep?: EventParams[];
	v?: number;
	t?: string;
	ul?: string;
	sr?: string;
}

const dataSendGa4 = (
	eventName: string,
	params?: EventParams[]
): DataSendGa4 => {
	return {
		en: eventName,
		ep: params,
		v: 2,
		t: "slime revolution website",
		ul: "en-us",
		sr: "1920x420",
	};
};

const fetchGa4 = async (
	data: DataSendGa4,
	uuid?: string | "1"
): Promise<void> => {
	let raw = `en=${data.en}`;

	if (data.ep !== undefined) {
		for (let i = 0; i < data.ep.length; i++) {
			raw += `&ep.${data.ep[i].key}=${data.ep[i].value}`;
		}
	}

	let requestOptions: RequestInit = {
		method: "POST",
		headers: {
			"Content-Type": "text/plain",
		},
		body: raw,
		redirect: "follow",
	};

	const measurement_id = `G-FLSSKTELR4`;
	let params: Record<string, string> = {
		tid: measurement_id,
		cid: "7f5e235b-2f46-4c7a-b43b-41fd4e1b4e10x",
		seg: "1",
		gtm: "45je4ag0v9186201277z89185519607za200zb9185519607",
		_p: "1729138181405",
		gcd: "13l3l3l3l1l1",
		npa: "0",
		dma: "0",
		tag_exp: "101686685",
		sr: "2560x1080",
		uaa: "arm",
		uab: "64",
		uafvl: "Google%2520Chrome%3B129.0.6668.100%7CNot%253DA%253FBrand%3B8.0.0.0%7CChromium%3B129.0.6668.100",
		uamb: "0",
		uam: "",
		uap: "macOS",
		uapv: "14.6.0",
		uaw: "0",
		are: "1",
		frm: "0",
		pscdl: "",
		sid: "1729132165",
		sct: "1",
		dl: "http%3A%2F%2Flocalhost%2F",
		dr: "http%3A%2F%2Flocalhost%3A3000%2F",
		dt: "Slime%20Revolution",
		_s: "1",
	};

	if (data.v) {
		params.v = String(data.v) || "2";
	}

	if (data.t) {
		params.t = String(data.t);
	}

	if (data.ul) {
		params.ul = String(data.ul);
	}

	// Thực hiện fetch tới Google Analytics API
	try {
		await fetch(
			`https://www.google-analytics.com/collect?${new URLSearchParams(
				params
			)}`,
			requestOptions
		);
	} catch (error) {
		console.error("Error sending data to GA4:", error);
	}
};
const sendRequestGoogleAnalyticsNew = (
	eventName: string,
	params: EventParams[] | undefined
): void => {
	const dataGa4: DataSendGa4 = dataSendGa4(eventName, params);
	fetchGa4(dataGa4);
};

export { sendRequestGoogleAnalyticsNew };

import ChoosePlanFarming from "../choosePlanFarming";
import iconPopup from "../../../assets/images/teleImage/icon_popup_tspc.png";
import styles from "./index.module.scss";
import ButtonClaim from "../../buttons/buttonClaim";
interface IModalConfirmTSPC {
	onClose: () => void;
}
const ModalConfirmTSPC = ({ onClose }: IModalConfirmTSPC) => {
	return (
		<ChoosePlanFarming iconPopup={iconPopup} onClose={onClose}>
			<div className={styles.popup}>
				<p>
					tSPC is the main currency in Slime Revolution Beta Phase.
					The more tSPC you collect, the bigger $SPC airdrop you earn
					after TGE.
				</p>
				<p
					style={{
						marginTop: "15px",
					}}
				>
					Cheating or exploiting platform vulnerabilities for personal
					gain will be tracked and permanently banned.
				</p>
				<ButtonClaim>
					<p>Got it</p>
				</ButtonClaim>
			</div>
		</ChoosePlanFarming>
	);
};

export default ModalConfirmTSPC;

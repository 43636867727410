import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import { devnetClient } from "../../../core/constants";
import { convertAptToOctas, convertOctasToApt, getAptosClient } from "../../../aptosConfig";
import { APTOS_COIN } from "@aptos-labs/ts-sdk";
import LoadingCommon from "../../CommonUI/LoadingApp";
import { getWalletFromLocalStorage } from "../../../utils/aptos/aptosStorage";

export interface CallbackCoin {
	(
		address: string,
		amount: number,
		transactionFee: string,
		hash: string
	): void;
}
type TransferCoinStyle = {
	onClose: () => void;
	onCallback: CallbackCoin;
};
export default function TransferCoinModal({
	onClose,
	onCallback,
}: TransferCoinStyle) {
	const [aptosCoin, setAptosCoin] = useState(0);
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [inputValueNumber, setInputValueNumber] = useState<number | "">(0);
	const [isShowLoading, setShowLoading] = useState(false);
	const [isShowAlert, setShowAlert] = useState<boolean>(false);
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputChangeNumber = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = event.target.value;
		console.log({ value });

		if (value === "" || /^(\d*\.?\d*)$/.test(value)) {
			setInputValueNumber(value === "" ? "" : parseFloat(value));
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isShowAlert]);

	const getBalance = async () => {
		const accountCoinsData: any = await devnetClient.getAccountCoinsData({
			accountAddress:
				getWalletFromLocalStorage()!.accountAddress.toString(),
		});
		console.log({ accountCoinsData });
		if (
			accountCoinsData.length > 0 &&
			convertOctasToApt(accountCoinsData[0].amount) > 0
		) {
			setAptosCoin(convertOctasToApt(accountCoinsData[0].amount));
		}
	};

	const transferCoinFun = async () => {
		const encryptedPrivateKeyGet = localStorage.getItem("encryptedPrivateKey");
		try {
			setShowLoading(true);
			console.log(
				Math.floor(convertAptToOctas(Number(inputValueNumber)))
			);
			const transaction = await devnetClient.transaction.build.simple({
				sender: getWalletFromLocalStorage()!.accountAddress,
				data: {
					function:  "0x1::aptos_account::transfer",
					// typeArguments: [APTOS_COIN],
					functionArguments: [
						inputValue,
						Math.floor(convertAptToOctas(Number(inputValueNumber))),
					],
				},
			});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getWalletFromLocalStorage()!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			console.log({ response });
			onCallback(
				inputValue,
				convertAptToOctas(Number(inputValueNumber)),
				"0.0001",
				response.hash
			);
			setShowLoading(false);
		} catch (e: any) {
			setShowAlert(true);
			setShowLoading(false);
			console.log({ e });
		}
	};

	useEffect(() => {
		getBalance();
	}, []);
	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<img
					src={buttonCancel}
					width={36}
					height={38}
					alt="button cancel"
					className={style.button__close}
					onClick={onClose}
				/>
				<div className={style.childStyle}>
					<p className={style.swapcointTile}>Transfer Coin</p>
				</div>

				<div className={style.container}>
					<p className={style.swapHeaderStyle}>Address</p>
					<input
						type="text"
						onChange={handleInputChange}
						className={style.inputStyle}
						placeholder="Paste wallet address here"
					/>
				</div>
				<div className={style.container}>
					<p className={style.swapHeaderStyle}>Network</p>
					<div className={style.aptStyle}>APT</div>
				</div>
				<div className={style.container}>
					<p className={style.swapHeaderStyle}>Amount</p>
					<div className={style.amountBody}>
						<input
							type="number"
							className={style.mintStyle}
							placeholder="Minium 0,06"
							value={inputValueNumber}
							onChange={handleInputChangeNumber}
						/>
						<div className={style.aptStyle1}>APT</div>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<span className={style.mintStyle1}>
							{`Balance: `}
							<span
								className={style.binanceStyle}
							>{`${aptosCoin.toFixed(2)}  APT`}</span>
						</span>
						<div
							className={`${style.maxStyle} active-btn`}
							onClick={() =>
								setInputValueNumber(
									Number((aptosCoin - 0.005).toFixed(2))
								)
							}
						>
							MAX
						</div>
					</div>
				</div>
				<div className={style.buttonStyle}>
					<CircleButton
						isGrayColor={
							inputValueNumber === 0 ||
							inputValueNumber.toString() === "" ||
							inputValue.toString() === "" ||
							Number(inputValueNumber) < 0.06 || 
							inputValueNumber >= aptosCoin.toFixed(2)
						}
						title="Confirm and Transfer"
						onTap={() => {
							if (
								!(
									inputValueNumber === 0 ||
									inputValueNumber.toString() === "" ||
									inputValue.toString() === "" ||
									Number(inputValueNumber) < 0.06 ||
									inputValueNumber >= aptosCoin.toFixed(2)
								)
							) {
								transferCoinFun();
							}
						}}
					/>
				</div>
			</div>
			{isShowAlert && (
				<div
					className={style.notice1}
				>{`Your total assets need to be 0.2 APT or more`}</div>
			)}
			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}

import React from 'react';
import styles from './index.module.scss'; // Import the SCSS module

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
};

const ModalSlimeV1: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay1}>
         <div className={styles.modalBody1}>{children}</div>
    </div>
  );
};

export default ModalSlimeV1;

// components/StarRating.js
import React from "react";
import startImg from "../../../assets/roll/card/start.png";
type StarRatingType = {
	count: number;
	size?: number;
};
export default function StarRating({ count, size }: StarRatingType) {
	return (
		<div>
			{Array.from({ length: count }, (_, index) => (
				<img
					key={index}
					src={startImg}
					alt={`star-${index}`}
					width={size ?? 16}
					height={size ?? 15}
				/>
			))}
		</div>
	);
}

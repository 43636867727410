/** @format */

import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import GameButton from "../../commonButton/gameButton/GameButton";
import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import StarRating from "../ModalBurnCard/StartRating";
type CardMintedModalType = {
	img: string;
	onClose: () => void;
	onMinted: () => void;
	children?: React.ReactNode;
	count: number;
};

export default function CardMintedModal({
	img,
	onClose,
	onMinted,
	children,
	count,
}: CardMintedModalType) {
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<p className={style.textgot}>You've got</p>
				<div className={style.startStyle}>
					<StarRating size={20} count={count} />
				</div>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img
					src={img}
					alt="img"
					width={200}
					style={{
						margin: "40px 0",
					}}
				/>
				{children}
				<p className={style.checkCollectionStyle} onClick={onMinted}>
					Check collection
				</p>
			</div>
		</div>
	);
}

/** @format */

import ChoosePlanFarming from "../choosePlanFarming";
import style from "./index.module.scss";
import iconUSDT from "../../../assets/roll/modals/USDT.png";
import {
	pushNoticeWithdraw,
	withDrawAPT,
} from "../../../services/accountApiService";
import { decodeJwt, encodeJwt } from "../../../utils/extension";
import ButtonClaim from "../../buttons/buttonClaim";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import { devnetClient } from "../../../core/constants";
import {
	feePayer,
	getWalletFromLocalStorage,
	myWalletAddress,
} from "../../../utils/aptos/aptosStorage";
import { APTOS_COIN } from "@aptos-labs/ts-sdk";
import { convertAptToOctas } from "../../../aptosConfig";
import LoadingCommon from "../../CommonUI/LoadingApp";
import { useEffect, useState } from "react";
import { useKeylessAccounts } from "../../../core/useKeylessAccounts";

type SrawUsdtType = {
	onClosePopup: () => void;
	onWithdrawSuccess: () => void;
	title: string;
	des: string;
};

export default function ModalWithDrawUSDT({
	onClosePopup,
	onWithdrawSuccess,
	title,
	des,
}: SrawUsdtType) {
	const [isShowLoading, setShowLoading] = useState(false);
	const [aptPrice, setAptPrice] = useState(0);
	const { activeAccount, disconnectKeylessAccount } = useKeylessAccounts();

	useEffect(() => {
		getPriceAPT();
	}, []);

	const getPriceAPT = async () => {
		const bnbUsdtPriceResponse = await fetch(
			"https://api.binance.com/api/v3/ticker/price?symbol=APTUSDT"
		);
		const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
		console.log(bnbUsdtPriceData.price);
		setAptPrice(bnbUsdtPriceData.price);
	};

	const requesWithDrawUSDT = async () => {
		try {
			setShowLoading(true);
			const value = {
				address_wallet: myWalletAddress(),
			};
			console.log(myWalletAddress());
			const payLoadData = await encodeJwt(value);
			const res = await withDrawAPT({ value: payLoadData });
		
			if (res !== 400) {
				const bnbUsdtPriceResponse = await fetch(
					"https://api.binance.com/api/v3/ticker/price?symbol=APTUSDT"
				);
				const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
				const transaction = await devnetClient.transaction.build.simple(
					{
						sender: feePayer!.accountAddress,
						data: {
							function: "0x1::aptos_account::transfer",
							typeArguments: [],
							functionArguments: [
								myWalletAddress(),
								Math.floor(
									convertAptToOctas(
										Number(5 / bnbUsdtPriceData.price)
									)
								),
							],
						},
					}
				);
				const senderAuthenticator = devnetClient.transaction.sign({
					signer: feePayer!,
					transaction,
				});
				const committedTransaction =
					await devnetClient.transaction.submit.simple({
						transaction,
						senderAuthenticator,
					});
				const response = await devnetClient.waitForTransaction({
					transactionHash: committedTransaction.hash,
				});
				const value1 = {
					AddressWallet: myWalletAddress(),
					transactionHash: response.hash,
				};
				const payLoadData = await encodeJwt(value1);
				pushNoticeWithdraw({ value: payLoadData });
				console.log({ response });
				onClosePopup();
				onWithdrawSuccess();
				setShowLoading(false);
			}
			setShowLoading(false);
		} catch (e) {
			setShowLoading(false);
			console.log({ e });
		}
	};

	return (
		<ChoosePlanFarming
			onClose={onClosePopup}
			iconPopup={iconUSDT}
			customStyle={{
				width: "230px",
				height: "auto",
				top: "-30%",
			}}
		>
			<div className={style.popup__usdt_wrapper}>
				<h2 className={style.popup__usdt_title}>{title}</h2>
				<p
					className={style.popup__usdt_description}
				>{`Congratulations! Your account is verified and eligible for withdrawal. You will receive $5 worth of APT, estimated at ~${Number(
					5 / aptPrice
				).toFixed(2)}APT.`}</p>
				<div className={style.popup__usdt_list_button}>
					<div>
						<div
							style={{
								marginTop: "50px",
							}}
						>
							<CircleButton
								isGrayColor={false}
								title="Withdraw $5 USDT"
								onTap={() => {
									requesWithDrawUSDT();
								}}
							/>
						</div>
					</div>
				</div>
				<LoadingCommon
					visible={isShowLoading}
					width={80}
					height={80}
					isLeft10={true}
				/>
			</div>
		</ChoosePlanFarming>
	);
}

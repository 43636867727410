/** @format */

import style from "./index.module.scss";
import cardImg from "../../../assets/images/teleImage/brgCard.png";
import tagImg from "../../../assets/images/teleImage/tagCard.png";
import buttonCancel from "../../../assets/images/back_icon.png";
import avtImg from "../../../assets/roll/card/Avt.png";
import cardMarket from "../../../assets/images/card/cardMarket.png";

import backImg from "../../../assets/images/card/BackImg.png";
import nextImg from "../../../assets/images/card/NextImg.png";
import imgT from "../../../assets/roll/card/imgT.png";
import { useEffect, useState } from "react";
import {
	collectionsById,
	getMarkePlace,
} from "../../../services/accountApiService";
import GameButton from "../../commonButton/gameButton/GameButton";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import Marketplace from "./itemsMarket";
import CardMarketplace from "./itemsMarket";
import { convertOctasToApt } from "../../../aptosConfig";

type CardMarketType = {
	id: number;
	onClose: () => void;
	onUpdate: () => void;
};

export default function CardMarketPage({
	onClose,
	id,
	onUpdate,
}: CardMarketType) {
	const [listNft, setListNft] = useState<MarketResponse | null>(null);

	const requestMarketPlace = async (id: number) => {
		const res: any = await getMarkePlace(id);
		setListNft(res);
		console.log(res);
	};

	useEffect(() => {
		requestMarketPlace(id);
	}, []);

	const StatsCard = () => {
		return (
			<div className={style.statsCard}>
				<div
					style={{
						display: "flex",
						columnGap: "4px",
						marginBottom: "8px",
					}}
				>
					<span className={style.stylespan}>Total Volume:</span>
					<span className={style.stylespanLast}>
						<strong>{`${listNft?.data.summary_market.total_volume.toFixed(
							2
						)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsRow}>
					<div className={style.statsItem}>
						<span>Floor Price:</span>
						<span>
							<strong>{`${listNft?.data.summary_market.floor_price.toFixed(
								2
							)} APT`}</strong>
						</span>
					</div>
					<div className={style.statsItem}>
						<span>Highest Price:</span>
						<span>
							<strong>{`${listNft?.data.summary_market.highest.toFixed(
								2
							)} APT`}</strong>
						</span>
					</div>
				</div>
				<div className={style.statsRow}>
					<div className={style.statsItem}>
						<span>Listed:</span>
						<span>
							<strong>{`${listNft?.data.summary_market.listed.toFixed(
								2
							)} %`}</strong>
						</span>
					</div>
					<div className={style.statsItem}>
						<span>Unique Owner:</span>
						<span>
							<strong>{`${listNft?.data.summary_market.unique_own.toFixed(
								2
							)} %`}</strong>
						</span>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img
					src={cardMarket}
					alt="slime"
					className={style.cardMarket}
				/>
				<StatsCard />
				<CardMarketplace idCard={id} onUpdate={onUpdate} />
			</div>
		</div>
	);
}

/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import bg_rectangle from "../../../assets/images/savePrivateKey.svg";
import desImg from "../../../assets/images/savePr.svg";
import titleImg from "../../../assets/images/Text.svg";
import warning from "../../../assets/images/warning.svg";
import iconCopyNew from "../../../assets/images/teleImage/icon_copy.png";
import { writeClipboardText } from "../../../utils/function/text";
import {
	getAccountPrivateKey,
	myWalletAddress,
} from "../../../utils/aptos/aptosStorage";
import { useEffect, useState } from "react";
import { collapseAddress } from "../../../core/utils";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";

type SavePrivateKeytype = {
	onClose: () => void;
};

export default function SavePrivateKeyModal({ onClose }: SavePrivateKeytype) {
	const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					src={bg_rectangle}
					width={350}
					alt="slibg_rectangleme"
					className={style.backgroundiMG}
				/>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<div className={style.childStyle}>
					<img src={titleImg} alt="titleImg" />
					<img
						src={desImg}
						alt="desImg"
						style={{
							marginTop: "10px",
						}}
					/>
					<div className={style.bodyPrvateKey}>
						<p className={style.privateKey}>
							{`Address: ${collapseAddress(myWalletAddress()!)}`}
						</p>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "280px",
							}}
						>
							<p className={style.privateKey}>
								Private key: {`**************`}
							</p>
							<img
								src={iconCopyNew}
								alt="icon copy"
								width={20}
								onClick={() => {
									setShowCheckIn(true);
									writeClipboardText(getAccountPrivateKey!());
								}}
							/>
						</div>
					</div>

					<img src={warning} alt="warning" width={314} style={{
						marginBottom : "20px"
					}} />

					<div style={{
						marginLeft : "16%"
					}}>
					<CircleButton title="I saved it" onTap={onClose} />
					</div>

					{showCheckIn && (
						<div className={style.notice1}>{`copied`}</div>
					)}
				</div>
			</div>
		</div>
	);
}

/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import bg_rectangle from "../../../assets/images/marketPlace/selecStarBgr.svg";
import cardRate from "../../../assets/images/marketPlace/selectImg.png";
import checkIcon from "../../../assets/images/marketPlace/checkIcon.svg";

import { useEffect, useState } from "react";
type SelectStarType = {
	starSelected: number;
	onClose: () => void;
	onSelect: ({ value }: any) => void;
};

export default function SelectStarModal({
	onClose,
	onSelect,
	starSelected,
}: SelectStarType) {
	const [indexSelection, setIndexSelection] = useState(-1);
	const levels = [0, 1, 2, 3, 4, 5];

	useEffect(() => {
		setIndexSelection(starSelected);
	}, []);
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					src={bg_rectangle}
					width={350}
					alt="slibg_rectangleme"
					className={style.backgroundiMG}
				/>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img
					src={cardRate}
					width={251}
					alt="slime"
					className={style.cardRateStyle}
				/>
				<div className={style.childStyle}>
					{levels.map((e, i) => (
						<div key={i}>
							<div
								className={style.itemStyle}
								onClick={() => {
									setIndexSelection(e);
									onSelect(e);
									onClose();
								}}
							>
								<p
									className={
										indexSelection === i
											? style.starStyleBold
											: style.startStyle
									}
								>{`${i !== 0 ? e : ""} ${
									e === 0
										? "All stars"
										: i === 1
										? "star"
										: "stars"
								}`}</p>
								{indexSelection === i && (
									<img src={checkIcon} alt="checkIcon" />
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

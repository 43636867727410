/** @format */

import style from "./index.module.scss";
import cardImg from "../../../assets/images/card/Setcompleted.png";
import GameButton from "../../commonButton/gameButton/GameButton";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import buttonCancel from "../../../assets/roll/button_cancel.png";
type ModalExchangeRewardType = {
	img: string;
	onClose: () => void;
	onClaim: () => void;
};

export default function ModalExchangeReward({
	img,
	onClose,
	onClaim,
}: ModalExchangeRewardType) {
	return (
		<div className={style.modalOverlay} onClick={onClose}>
			<div className={style.main}>
			<img
					onClick={onClose}
					src={buttonCancel}
					width={20}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img src={cardImg} alt="cardImg" height={60} />
				<p className={style.titleTEXT}>You can exchange for</p>
				<img
					src={img}
					alt="img"
					style={{
						marginBottom: "40px",
					}}
				/>
				<CircleButton title="Exchange Reward" onTap={onClaim} />
			</div>
		</div>
	);
}

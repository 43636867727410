/** @format */

import style from "./index.module.scss";
import bgrBuyMana from "../../../assets/images/Popupv2.svg";
import op2 from "../../../assets/images/1_ton.svg";
import op1 from "../../../assets/images/1.svg";
import Countdown from "react-countdown";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import CardLevelInfoModal from "../CardLevelInfo";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import { useState } from "react";
import { decodeJwt, encodeJwt, tonToNanoton } from "../../../utils/extension";
import {
	buyMana,
	requestUpdateProfile,
} from "../../../services/accountApiService";
import { useTonConnectUI } from "@tonconnect/ui-react";
type BuyManaType = {
	onClose: () => void;
};

export default function BuyManaModal({ onClose }: BuyManaType) {
	const [isShowInfo, setShowInfo] = useState(false);
	const [tonConnectUI, setOptions] = useTonConnectUI();

	const requestBuyMana = async (
		item_purchase: number,
		transaction_hash: string
	) => {
		const value = {
			item_purchase: item_purchase,
			transaction_hash: transaction_hash,
		};
		const payLoadData = await encodeJwt(value);

		const buyManaRes = await buyMana({
			value: payLoadData,
		});
		onClose();
		console.log({ buyManaRes });
	};

	const openUrl = (url: string) => {
		window.open(url, "_blank");
	};

	const sendTransaction = async (item_purchase: number) => {
		await tonConnectUI.openModal();
		try {
			const hash = await tonConnectUI.sendTransaction({
				validUntil: Math.floor(Date.now() / 1000) + 60,
				messages: [
					{
						address:
							"UQBkkD_ESQA64FNXlCQve2673XcCgx7gm4h9rBfewBtEvKcj",
						amount: `${tonToNanoton(
							item_purchase === 1 ? 0.1 : 1
						).toFixed(0)}`,
					},
				],
			});
			await requestUpdateProfile(tonConnectUI.account?.address, "Ton");
			requestBuyMana(item_purchase, hash.boc);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img src={bgrBuyMana} alt="bgrBuyMana" />
				<p className={style.titleRun}>Run out of Mana</p>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<div className={style.titleDiv}>
					<p>
						We charge a minimal Mana fee to cover gas costs. Choose
						your package!
					</p>
					<p
						className={style.viewTransaction}
						onClick={() => {
							openUrl(
								"https://aptoscan.com/account/0x08afb046f44dd0cb9c445458f9c2e424759cd11f4a270fe6739dcffc16a4db8e"
							);
						}}
					>
						🔗 View Game Transactions
					</p>
				</div>
				<div className={style.bodyBuy}>
					<img
						src={op1}
						height={165}
						width={130}
						alt="op1"
						className="active-btn"
						onClick={() => {
							sendTransaction(1);
						}}
					/>
					<img
						width={150}
						height={165}
						src={op2}
						alt="op2"
						className="active-btn"
						onClick={() => {
							sendTransaction(2);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import bg_rectangle from "../../../assets/images/marketPlace/selecStarBgr.svg";
import cardRate from "../../../assets/images/marketPlace/selectImg.png";
import checkIcon from "../../../assets/images/marketPlace/checkIcon.svg";

import { useEffect, useState } from "react";
type SelectTimeType = {
	timeSelected: string;
	onClose: () => void;
	onSelect: ({ value }: any) => void;
};

export default function SelectTimeModal({
	onClose,
	onSelect,
	timeSelected,
}: SelectTimeType) {
	const [indexSelection, setIndexSelection] = useState("");
	const timeOptions = ["1h", "6h", "24h", "7d"];

	useEffect(() => {
		setIndexSelection(timeSelected);
	}, []);
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					src={bg_rectangle}
					width={350}
					alt="slibg_rectangleme"
					className={style.backgroundiMG}
				/>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img
					src={cardRate}
					width={251}
					alt="slime"
					className={style.cardRateStyle}
				/>
				<div className={style.childStyle}>
					{timeOptions.map((e, i) => (
						<div key={i}>
							<div
								className={style.itemStyle}
								onClick={() => {
									setIndexSelection(e);
									onSelect(e);
									onClose();
								}}
							>
								<p
									className={
										indexSelection === e
											? style.starStyleBold
											: style.startStyle
									}
								>
									{e}
								</p>
								{indexSelection === e && (
									<img src={checkIcon} alt="checkIcon" />
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

/** @format */

import { ENDPOINT } from "./endpoint";
import instance from "../baseApi";
import { decodeJwt } from "../../utils/extension";

const apiInfoFarming = async (payloadRequest?:any) => {
    try {
        const responseGetInfo = await instance.get(
            ENDPOINT.INFO_FARM,
            payloadRequest
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
        return false;
    }
};
const apiStartFarming = async (payloadRequest: any) => {
    try {
        const responseGetInfo = await instance.post(
            `${ENDPOINT.START_FARM}/${payloadRequest}`,
            { Type: payloadRequest },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
        return false;
    }
};

const apiStartJourney = async (payloadRequest : any) => {
    try {
        const responseGetInfo = await instance.put(
            ENDPOINT.START_JOURNEY,
            payloadRequest
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
        return false;
    }
};
/**
 * register with address
 * @param {*} payloadRequest
 * @returns
 */
const apiRegisterAccountWithAddress = async (payloadRequest : any) => {
    try {
        const responseGetInfo = await instance.post(
            ENDPOINT.REGISTER,
            payloadRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
    }
};

const apiReceiveClaim = async (payloadRequest : any) => {
    try {
        const responseGetInfo = await instance.post(
            `${ENDPOINT.RECEIVE_CLAIM}/${payloadRequest}`,
            payloadRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
    }
};

const apiPillageJourneyInfo = async (payloadRequest ?: any) => {
    try {
        const responseGetInfo = await instance.get(
            ENDPOINT.PILLAGE_JOURNEY_INFO,
            payloadRequest
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
        return false;
    }
};

const requestSkipBuildingApi = async (payloadRequest : any) => {
    try {
        const res = await instance.post(
            ENDPOINT.SKIPBUILDING,
            payloadRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (res) return await decodeJwt(res?.data);
    } catch (err) {
        console.log(err);
    }
};

const apiLevelUp = async (payloadRequest: any) => {
    try {
        const responseGetInfo = await instance.post(
            `${ENDPOINT.UP_LEVEL_SHIP}`,
            payloadRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
    }
};

const apiGetMyAccount = async () => {
    try {
        const responseGetInfo = await instance.get(
            ENDPOINT.USER_INFO,
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
        return false;
    }
};

export {
    apiInfoFarming,
    apiStartFarming,
    apiStartJourney,
    apiRegisterAccountWithAddress,
    apiPillageJourneyInfo,
    apiReceiveClaim,
    apiLevelUp,
    apiGetMyAccount,
    requestSkipBuildingApi
};

/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import Mysterycard from "../../../assets/images/card/Mysterycard.png";
import InfoImg from "../../../assets/images/ranking/inFoIcon.png";
import earnImg from "../../../assets/images/earnMintCard.svg";
import gasSponsor from "../../../assets/images/card/gasSponsor.png";
import addBtn from "../../../assets/images/add_btn.svg";
import Countdown from "react-countdown";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import CardLevelInfoModal from "../CardLevelInfo";
import { useEffect, useState } from "react";
import BuyManaModal from "../BuyManaModal";
import { apiGetMyAccount } from "../../../services/accountApiService";
type CardMBeforeMintModalType = {
	onClose: () => void;
	ExpiredTimeMint: string;
	cardLevel: number;
	onClaim: () => void;
	onConnectWallet: () => void;
};

export default function CardBeforeMintModal({
	onClose,
	ExpiredTimeMint,
	cardLevel,
	onClaim,
	onConnectWallet,
}: CardMBeforeMintModalType) {
	const [isShowInfo, setShowInfo] = useState(false);
	const [isShowBuyMana, setShowBuyMana] = useState(false);
	const [inforAccount, setAccountInfo] = useState(null);
	const handleCallApiInFoUser = async () => {
		const responseInfoFarming: any = await apiGetMyAccount();
		if (responseInfoFarming) {
			setAccountInfo(responseInfoFarming?.data);
		}
		console.log({ responseInfoFarming });
	};

	useEffect(() => {
		handleCallApiInFoUser();
	}, []);

	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<p className={style.card_level}>Card Level: {cardLevel}</p>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						columnGap: "4px",
						marginBottom: "10px",
					}}
				>
					<p className={style.cardRateStyle}>Card rate</p>
					<img
						src={InfoImg}
						width={20}
						alt="InfoImg"
						onClick={() => setShowInfo(true)}
					/>
				</div>
				<div
					className={style.manaBody}
					onClick={() => {
						setShowBuyMana(true);
					}}
				>
					<p className={style.yourMana}>Your mana</p>
					{inforAccount && (
						<p className={style.mamaCount}>
							{Number(inforAccount!["Mana"]).toLocaleString()}
						</p>
					)}
				</div>
				<img
					src={addBtn}
					alt="addBtn"
					className={style.addBtnStyle}
					onClick={() => {
						setShowBuyMana(true);
					}}
				/>

				<img src={Mysterycard} alt="Mysterycard" height={150} />
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<p className={style.expiredTime}>{`Expired time:  `} </p>
					<Countdown
						className={style.expiredTime}
						date={Number(new Date(ExpiredTimeMint).getTime())}
						onComplete={() => {}}
					/>
				</div>
				<img src={earnImg} alt="earnImg" width={280} />
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<div
					style={{
						paddingTop: "70px",
						paddingBottom: "4px",
					}}
				>
					{inforAccount && (
						<CircleButton
							isShowMana={true}
							title={"100 Mana"}
							isGrayColor={Number(inforAccount!["Mana"]) < 100}
							onTap={
								Number(inforAccount!["Mana"]) >= 100
									? onClaim
									: () => {}
							}
						/>
					)}
				</div>
				{/* <img src={gasSponsor} alt="gasSponsor" width={200} /> */}
				{isShowInfo && (
					<CardLevelInfoModal
						onClose={() => {
							setShowInfo(false);
						}}
						cardLevel={cardLevel}
					/>
				)}
				{isShowBuyMana && (
					<BuyManaModal
						onClose={async () => {
							setShowBuyMana(false);
							await handleCallApiInFoUser();
						}}
					/>
				)}
			</div>
		</div>
	);
}

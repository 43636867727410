/** @format */

import instance from "../baseApi";
import axios from "axios";
import { ENDPOINTS } from "./endpoint";
import { decodeJwt, encodeJwt } from "../../utils/extension";

export const checkInDaily = () => {
	return instance.post(ENDPOINTS.API_CHECK_IN_CONTRACT);
};

export const telegramUser = () => {
	return instance.get(ENDPOINTS.API_QUEST_TELEGRAM);
};

export const telegramConfirm = (questId: number) => {
	let url = `${ENDPOINTS.API_QUEST_TELEGRAM_CONFIRM}${questId}`;
	return instance.post(url);
};

export const checkInToday = (data: {}) => {
	return instance.post(ENDPOINTS.API_INFO_CHECK_IN_SMART_WALLET, data);
};

export const getMyAccount = () => {
	return instance.get(ENDPOINTS.API_USER_INFO);
};

export const updateMyAccount = (data: {}) => {
	return instance.post(ENDPOINTS.API_UPDATE_SMART_WALLET, data);
};

export const startWithDraw = async (data?: {}) => {
	try {
		const responseGetInfo = await instance.post(
			ENDPOINTS.API_WITHDRAW_START_CLICK
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};
export const unSellCard = async (data?: {}) => {
	try {
		const responseGetInfo = await instance.post(
			ENDPOINTS.API_UN_SELL,
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const withDrawAPT = async (data?: {}) => {
	try {
		const responseGetInfo = await instance.post(
			ENDPOINTS.API_WITHDRAW_APT,
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return 400;
	}
};

export const pushNoticeWithdraw = async (data?: {}) => {
	try {
		const responseGetInfo = await instance.post(
			'/api/v1/withdraw/update-status-with-draw-notification',
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return 400;
	}
};

export const requestGetRanking = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_RANKING);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const withdrawTimeInfo = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_WITHDRAW_INFO);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const withDrawUsd = () => {
	return instance.post(ENDPOINTS.API_WITHDRAW_USDT);
};

export const changeStatusWithDraw = (data: {}) => {
	return instance.post(ENDPOINTS.API_WITHDRAW_UPDATE_STATUS, data);
};
export const paymentRequest = (data: {}) => {
	return instance.post(ENDPOINTS.API_WITHDRAW_UPDATE_PAYMENT, data);
};

export const getShopPackageData = () => {
	return instance.get(ENDPOINTS.API_SHOP_PACKAGE);
};

export const getCollectionList = async () => {
	try {
		const responseGetInfo = await instance.get(
			ENDPOINTS.API_COLLECTIONS_LIST
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};
export const requestListCardSyncData = async () => {
	try {
		const responseGetInfo = await instance.get(
			ENDPOINTS.API_LIST_CARD_TRANSFER
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const requestUpdateStatusMintCard = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			ENDPOINTS.API_UPDATE_STATUS_MINT_CARD,data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const buyMana = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			ENDPOINTS.API_PURCHASE_MANA,data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};


export const getCardListed = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_LISTED_CARDS);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const getCardUnList = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_UN_LIST_CARDS);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const getListCardReadyToMint = async () => {
	try {
		const responseGetInfo = await instance.get(
			ENDPOINTS.API_CARDS_WAIT_LISTS_MINT
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const collectionsById = async (id: number) => {
	try {
		const responseGetInfo = await instance.get(
			`${ENDPOINTS.API_COLLECTIONS_LIST_CARDS}${id}`
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};
export const getIdBurnCard = async (id: number) => {
	try {
		const responseGetInfo = await instance.get(
			`/api/v1/collections/list-token-burn/${id}`
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const check7day = () => {
	return instance.get(ENDPOINTS.API_USER_REWARD_7DAY);
};

export const reward7Day = () => {
	return instance.post(ENDPOINTS.API_USER_REWARD_7DAY_POST);
};

export const packageInfo = (userId: number) => {
	return instance.get(`${ENDPOINTS.API_SHOP_PACKAGE_USER}${userId}`);
};

export const getUserId = (id: number) => {
	return instance.get(`${ENDPOINTS.API_USER_BY_ID}${id}`);
};

export const apiGetMyAccount = async (payloadRequest?: {}) => {
	try {
		const responseGetInfo = await instance.get(
			ENDPOINTS.USER_INFO,
			payloadRequest
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const getInfoCardMinted = async (id: number) => {
	try {
		const responseGetInfo = await instance.get(
			`/api/v1/marketplace/info-card?id=${id}`
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const checkedInRequest = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/smart-wallet/energy-reward",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};
export const updateSmartWallet = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			ENDPOINTS.UPDATE_SMART_WALLET,
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};
export const requestMinCard = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/cards/mint-cards",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const requestSubmitMinCard = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/cards/update-status-mint-card",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const sellCardNft = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/marketplace/sell-card",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const updateCardPriceNft = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/marketplace/update-price-card",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const requestBuyCardNft = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/marketplace/buy-card",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const requestBurnCardNft = async (data: any) => {
	try {
		const responseGetInfo = await instance.post(
			"/api/v1/collections/reward",
			data
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return 404;
	}
};

export const getAllCardOfID = async (id: number) => {
	try {
		const responseGetInfo = await instance.get(
			`/api/v1/cards/sell-info/${id}`
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const getMarkePlace = async (id: number) => {
	try {
		const responseGetInfo = await instance.get(
			`/api/v1/marketplace/list-card-sell?type=${id}`
		);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const getAllMarkePlace = async (collection_id?: number, star?: number) => {
	try {
		let url = `/api/v1/marketplace/list-card-info?page=1&limit=20`;
		if (collection_id !== undefined) {
			url += `&collection_id=${collection_id}`;
		}
		if (star !== undefined) {
			url += `&star=${star}`;
		}
		const responseGetInfo = await instance.get(url);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const requestUpdateProfile = async (
	walletAddress?: {},
	walletType?: string
) => {
	try {
		const value = {
			address_smart_wallet: walletAddress,
			wallet_type: walletType,
		};
		const payLoadData = await encodeJwt(value);
		const res = await updateMyAccount({ value: payLoadData });
	} catch (e) {
		console.log({ e });
	}
};

export const apiRewardTspc = async () => {
	try {
		const responseRewardTspc = await instance.post(
			"/api/v1/user/reward-tspc"
		);
		if (responseRewardTspc)
			return await decodeJwt(responseRewardTspc?.data);
	} catch (e) {
		console.log({ e });
	}
};

/** @format */

import React, { useEffect, useState } from "react";
import collectionImg from "../../../../../../assets/roll/card/collection.png";
import cardImg from "../../../../../../assets/images/card/BG_1.png";
import card1 from "../../../../../../assets/images/card/card2.png";
import card2 from "../../../../../../assets/images/card/card3.png";
import card3 from "../../../../../../assets/images/card/card1.png";
import syncCard from "../../../../../../assets/images/card/syncCard.png";

import style from "./index.module.scss";
import { getCollectionList } from "../../../../../../services/accountApiService";
import ModalBurnCard from "../../../../../../commonsUi/modals/ModalBurnCard";
import CardMarketPage from "../../../../../../commonsUi/modals/CardMarket";
import UnlistCardNFT from "../unlistCard";
import ListedCard from "../listedCard";

type CollectionsCardType = {
	collectionList: CollectionModel[];
	onTapSyncCard: () => void;
	isShowSyncCard: boolean;
};

export default function CollectionsCard({
	collectionList,
	onTapSyncCard,
	isShowSyncCard,
}: CollectionsCardType) {
	const [itemSelected, setItemSelected] = useState<CollectionModel | null>(
		null
	);
	const [isOpenBurn, setOpenBurn] = useState(false);

	const backgroundImages = [card1, card2, card3];
	const [activeTab, setActiveTab] = useState("Collections");

	interface BuildProgressProps {
		current: number;
		total: number;
	}
	const BuildProgress: React.FC<BuildProgressProps> = ({
		current,
		total,
	}) => {
		const percentage =
			total !== 0 ? ((current / total) * 100).toFixed(2) : "0.00";

		return (
			<div className={style.progressBarContainer}>
				<div
					className={style.progressBar}
					style={{
						width: `${percentage}%`,
					}}
				></div>
				<div
					className={style.progressText}
				>{`${current}/${total}`}</div>
			</div>
		);
	};

	return (
		<div className={style.main}>
			<div className={style.headerStyle}>
				<img className={style.cardStyle} src={cardImg} alt="cardImg" />
				<img
					className={style.tagCardImg}
					src={collectionImg}
					alt="collectionImg"
				/>
				<div className={style.bodyHeader}>
					<div className={style.tabsContainer}>
						<div
							className={`${style.tab} ${
								activeTab === "Collections"
									? style.activeTab
									: ""
							}`}
							onClick={() => setActiveTab("Collections")}
						>
							Collections
						</div>
						<div
							className={`${style.tab} ${
								activeTab === "Unlisted Cards"
									? style.activeTab
									: ""
							}`}
							onClick={() => setActiveTab("Unlisted Cards")}
						>
							Unlisted Cards
						</div>
						<div
							className={`${style.tab} ${
								activeTab === "Listed Cards"
									? style.activeTab
									: ""
							}`}
							onClick={() => setActiveTab("Listed Cards")}
						>
							Listed Cards
						</div>
					</div>
					{isShowSyncCard && (
						<img
							onClick={onTapSyncCard}
							src={syncCard}
							alt="syncCard"
							width={130}
							style={{ marginTop: "8px" }}
						/>
					)}

					{activeTab === "Collections" &&
						collectionList?.map((e, index) => {
							const randomImage =
								backgroundImages[
									Math.floor(
										Math.random() * backgroundImages.length
									)
								];
							return (
								<div
									key={index}
									className={`${style.item} active-btn`}
									style={{
										backgroundImage: `url(${randomImage})`,
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										backgroundSize: "contain",
									}}
									onClick={() => {
										setItemSelected(e);
										setOpenBurn(true);
									}}
								>
									<div className={style.leftItem}>
										<img
											src={e.Image}
											alt="avtImg"
											width={200}
											height={200}
											className={style.imgItemStyle}
										/>
										<p className={style.leftItemText}>
											{e.CollectionName}
										</p>
									</div>
									<div className={style.rightItem}>
										<p className={style.titleItem}>
											complete the set to win
										</p>
										<img
											src={e.Rewards.RewardImage}
											alt="Reward30s"
											// width={150}
											style={{
												margin: "4px 0",
											}}
											height={36}
										/>
										<BuildProgress
											current={e.card_number}
											total={e.QuantityCard}
										/>
									</div>
								</div>
							);
						})}
					{activeTab === "Unlisted Cards" && <UnlistCardNFT />}
					{activeTab === "Listed Cards" && <ListedCard />}
				</div>
				{isOpenBurn && (
					// <CardMarketPage onClose={() => setOpenBurn(false)} />
					<ModalBurnCard
						img={itemSelected!.Rewards.RewardImage}
						idCardSelect={itemSelected!.Id}
						onClose={() => setOpenBurn(false)}
						itemSelected={itemSelected!}
					/>
				)}
			</div>
		</div>
	);
}
